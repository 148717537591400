[data-amplify-color-mode='light'] {
	body {
		background-color: black;
	}
	.amplify-liveness-toast--primary {
		background-color: #fff;
		margin-top: -20px;
		.amplify-liveness-toast__message {
			color: #0f0f0f;
			font-size: 25px;
			font-weight: 500;
		}
	}
	.amplify-button--primary {
		padding-inline: 65px;
		padding-block: 6px;
		margin-block: 13px;
	}
	.amplify-liveness-recording-icon {
		/* margin-top: 20px; */
	}
	.amplify-liveness-cancel-container {
		display: none;
		.amplify-liveness-cancel-button {
			display: none;
		}
		.amplify-button:focus {
			box-shadow: none;
		}
	}
	.amplify-liveness-camera-module {
		border: 1px solid white;
	}
	.amplify-button--primary:focus {
		outline: none;
	}
}

[data-amplify-color-mode='dark'] {
	body {
		background-color: black;
	}
	.liveness-detector {
		background-color: #0f0f0f;
	}
	.amplify-liveness-toast--primary {
		background-color: transparent;
		margin-top: -50px;
		.amplify-liveness-toast__message {
			/* color: #0f0f0f; */
			font-size: 25px;
			font-weight: 500;
		}
	}

	.amplify-button--primary {
		padding-inline: 65px;
		padding-block: 6px;
		margin-block: 13px;
	}
	.amplify-liveness-cancel-container {
		display: none;
		.amplify-liveness-cancel-button {
			display: none;
		}
		.amplify-button:focus {
			box-shadow: none;
		}
	}
	.amplify-liveness-camera-module {
		border: 1px solid #0f0f0f;
		background-color: #0f0f0f;
	}
	.amplify-button--primary:focus {
		outline: none;
	}
}
